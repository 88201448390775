























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import SubscriptionPrintMode from '@/partials/components/Events/SubscriptionPrintMode.vue'
import ManualPaymentOperation from '@/partials/modals/Events/Operations/ManualPayment.vue'
import CancelSubscriptionOperation from '@/partials/modals/Events/Operations/CancelSubscription.vue'
import TransferSubscriptionOperation from '@/partials/modals/Events/Operations/TransferSubscription.vue'
import PrintSubscriptionOperation from '@/partials/modals/Events/Operations/PrintSubscriptionOperation.vue'
import SubscriptionsHistoryTable from '@/partials/tables/Events/Subscriptions/History/index.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

const sessionModule = namespace('session')

@Component({
  components: {
    SubscriptionsHistoryTable,
    PrintSubscriptionOperation,
    SubscriptionPrintMode,
    ManualPaymentOperation,
    CancelSubscriptionOperation,
    TransferSubscriptionOperation
  }
})
export default class EventSubscriptionOperations extends Mixins(FlashMessageMixin) {
  @Prop({ required: true }) subscription!: any
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: Boolean }) isEventShow!: boolean
  @sessionModule.Action logout!: () => Promise<any>
  internalSubscription = {} as any

  created () {
    this.internalSubscription = this.subscription
  }

  redirectAfterCancel (message: string) {
    const flashMessage: flashMessage = {
      message,
      type: 'success'
    }

    if (this.isEventShow) {
      this.logout()
        .finally(() => {
          this.$router.push({ name: 'Login' })
        })
    } else {
      this.$router.push({ name: 'EventSubscribers', params: { id: this.eventId } }, () => {
        this.setFlashMessage(flashMessage)
      })
    }
  }

  done = true
}
